document.addEventListener('DOMContentLoaded', function() {
	$('.logo-carousel-wrap').each(function() {
		var carousel = $(this).find('.logo-carousel');
		var next     = $(this).find('.swiper-button-next');
		var prev     = $(this).find('.swiper-button-prev');

		var logoCarousel = new Swiper(carousel[0], {
			slidesPerView: 3,
			spaceBetween: 20,
			loop: true,
			speed: 900,
			centeredSlides: true,
			simulateTouch: false,
			grabCursor: true,
			autoplay: {
				delay: 2000,
				disableOnInteraction: false
			},
			navigation: {
				nextEl: next[0],
				prevEl: prev[0]
			},
			breakpoints: {
				715: {
					slidesPerView: 4
				},
				975: {
					slidesPerView: 5
				},
				1250: {
					slidesPerView: 8
				},
				1650: {
					slidesPerView: 9
				}
			},
			watchSlidesProgress: true,
			watchSlidesVisibility: true,
			on: {
				slideChangeTransitionStart: function() {
					logoCarousel.slides.forEach(function(slide) {
						slide.setAttribute('aria-hidden', 'true');
					});

					logoCarousel.activeIndex === 0
						? logoCarousel.slides[logoCarousel.slides.length - 2].setAttribute('aria-hidden', 'false')
						: logoCarousel.slides[logoCarousel.activeIndex - 1].setAttribute('aria-hidden', 'false');

					logoCarousel.activeIndex === logoCarousel.slides.length - 1
						? logoCarousel.slides[1].setAttribute('aria-hidden', 'false')
						: logoCarousel.slides[logoCarousel.activeIndex + 1].setAttribute('aria-hidden', 'false');
					logoCarousel.slides[logoCarousel.activeIndex].setAttribute('aria-hidden', 'false');

					logoCarousel.slides.forEach(function(slide) {
						slide.querySelectorAll('a').forEach(function(link) {
							link.setAttribute('tabindex', '-1');
							link.setAttribute('aria-hidden', 'true');
						});
					});

					logoCarousel.slides[logoCarousel.activeIndex].querySelectorAll('a').forEach(function(link) {
						link.setAttribute('tabindex', '0');
						link.setAttribute('aria-hidden', 'false');
					});
				}
			}
		});
	});

	$('.logo-carousel .swiper-slide').attr('aria-hidden', 'true');
});