document.addEventListener('DOMContentLoaded', function() {
	// Delegate Click Functionality
	$(this.body).on('click', '.titlebar-trim-faq [data-component-action]', function(event) {
		// Prevent Default
		event.preventDefault();

		// Variable Defaults
		var payload  = $(this).data('component') || $(this).parents('[data-component]').data('component');
		var action   = $(this).data('component-action');
		var endpoint = $(this).closest('[data-endpoint]').data('endpoint');

		// Switch Action
		switch(action) {
			case 'modal':
				// Render Modal
				fetch(endpoint, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(payload)
				}).then(response => response.text()).then(html => {
					const elem  = document.createRange().createContextualFragment(html).children[0];
					const modal = new bootstrap.Modal(elem);
					modal._element.addEventListener('hidden.bs.modal', destroyModal);
					modal.show();
				}).catch(error => alert(error));
				break;
			default:
				console.error('Unknown Component Action', action);
		}
	});
});