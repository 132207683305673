/**||--------------------------------------------------------||**
 **||				<- Lazy Load Images ->					 ||**
 **||--------------------------------------------------------||**/
if(typeof Layzr === 'function') {
	// Init Layzr
	var layzrInstance = Layzr({
		normal: 'data-src',
		retina: 'data-retina',
		srcset: 'data-srcset',
		threshold: 50
	});

	// Add Callbacks
	layzrInstance.on('src:before', function(image) {
		// Check for Empty Source
		image.dataset.src = image.dataset.src || image.src;
	});

	// Start When DOM is Ready
	document.addEventListener('DOMContentLoaded', function() {
		layzrInstance.update().check().handlers(true);
	});
} else {
	$(window).on('load', function() {
		// Reducing the amount of images loaded by decreasing measured distance from bottom of screen
		var initialLoad = 400;

		//6.929931640625ms to acquire initial images
		setTimeout(function() {
			swapLazyImages($('.lazy'), initialLoad);
		}, 1000);

		//ON SCROLL LOAD IMAGES
		window.addEventListener('scroll', function() {
			swapLazyImages($('.lazy'), initialLoad);
		}, { passive: true });

		function swapLazyImages(lazyImages, initialLoad) {
			for(var i = 0; i < lazyImages.length; i++) {
				if(lazyImages[i].getBoundingClientRect().top - window.innerHeight < initialLoad) {
					lazyImages[i].src = lazyImages[i].dataset.src;
					lazyImages[i].classList.remove('lazy');
				}
			}
		}
	});
}

/**
 * Extend jQuery to Filter by Data
 *
 * @param prop
 * @param val
 * @returns {*}
 */
$.fn.filterByData = function(prop, val) {
	return this.filter(function() {
		return $(this).data(prop) === val;
	});
};

/**
 * Extend jQuery to Serialize Object from Forms
 *
 * @returns {{}}
 */
$.fn.serializeObject = function() {
	var self          = this;
	var json          = {};
	var push_counters = {};
	var patterns      = {
		'validate': /^[a-zA-Z][a-zA-Z0-9_\-]*(?:\[(?:\d*|[a-zA-Z0-9_\-]+)])*$/,
		'key': /[a-zA-Z0-9_\-]+|(?=\[])/g,
		'push': /^$/,
		'fixed': /^\d+$/,
		'named': /^[a-zA-Z0-9_\-]+$/
	};

	this.build = function(base, key, value) {
		base[key] = value;
		return base;
	};

	this.push_counter = function(key) {
		if(push_counters[key] === undefined) {
			push_counters[key] = 0;
		}
		return push_counters[key]++;
	};

	$.each($(this).serializeArray(), function() {
		// Skip invalid keys
		if(!patterns.validate.test(this.name)) {
			return;
		}

		var k;
		var keys        = this.name.match(patterns.key);
		var merge       = this.value;
		var reverse_key = this.name;

		while((k = keys.pop()) !== undefined) {
			// Adjust reverse_key
			reverse_key = reverse_key.replace(new RegExp('\\[' + k + '\\]$'), '');

			if(k.match(patterns.push)) {
				merge = self.build([], self.push_counter(reverse_key), merge);
			} else if(k.match(patterns.named)) {
				merge = self.build({}, k, merge);
			} else if(k.match(patterns.fixed)) {
				merge = self.build([], k, merge);
			}
		}

		json = $.extend(true, json, merge);
	});

	return json;
};

/**
 * Document Ready Section
 */
$(function() {
	/**
	 * Bind Data for Select Boxes
	 */
	$('body').on('change', '.select-wrap', function() {
		// Set Option
		var option = $(this).find('option:selected').text();

		// Update HTML
		$(this).find('.select-box').html(option);
	}).find('select[data-value]').each(function() {
		// Check Value
		if($(this).find('option[value="' + this.dataset.value + '"]').length) {
			// Set Value
			$(this).val(this.dataset.value);
		}
	}).end().find('select[data-values]').each(function() {
		// Set Values
		$(this).val(JSON.parse(this.dataset.values));
	}).end().find('.select-wrap').trigger('change');

	/**
	 * Checkbox Script
	 */
	$('.check-btn').each(function() {
		$(this).on('click', function() {
			$(this).toggleClass('checked-btn');
		});

		if($(this).find('input').is(':checked')) {
			$(this).addClass('checked-btn');
		}
	});

	/**
	 * Display Cookie Message in Alert Modal
	 */
	(function(message) {
		// Check Message
		if(message.hasOwnProperty('message')) {
			displayMessage(message.text, message.severity, function() {
				Cookies.remove('message');
			});
		}
	})(JSON.parse(Cookies.get('message') || '[]'));

	/**
	 * jQuery Mask Plugin
	 */
	$(':input[data-format]').each(function() {
		// Variable Default
		var type = $(this).data('format');

		// Switch Type
		switch(type) {
			case 'date':
				$(this).mask('00/00/0000');
				break;
			case 'mm/yy':
				$(this).mask('00/00', { placeholder: 'mm/yy' });
				break;
			case 'mm/yyyy':
				$(this).mask('00/0000', { placeholder: 'mm/yyyy' });
				break;
			case 'fax':
			case 'phone':
				$(this).mask('(000) 000-0000');
				break;
			case 'fax-alt':
			case 'phone-alt':
				$(this).mask('000-000-0000');
				break;
			case 'postal':
			case 'zip':
				$(this).mask('00000');
				break;
			case 'number':
				$(this).mask('000,000,000,000,000.00', { reverse: true });
				break;
			default:
				console.error('Unknown Mask Type:', type);
		}
	});

	/**
	 * MultiSelect
	 */
	$('select[multiple]:not(.custom)').multiSelect({
		selectableHeader: '<div class="text-center"><b>Inactive</b></div>',
		selectionHeader: '<div class="text-center"><b>Active</b></div>',
		selectableFooter: '<button class="btn btn-primary btn-select-all d-block w-100" type="button">Select All</button>',
		selectionFooter: '<button class="btn btn-primary btn-deselect-all d-block w-100" type="button">Deselect All</button>',
		afterInit: function(multiSelect) {
			/* Variable Defaults */
			const element = this.$element;
			const wrapper = $('<div class="col-xl-6"/>');

			// Custom Classes
			this.$container.addClass('row w-auto');
			this.$selectableContainer.addClass('w-100 px-xl-3 mb-4 mb-xl-0').wrap(wrapper);
			this.$selectionContainer.addClass('w-100 px-xl-3 mt-4 mt-xl-0').wrap(wrapper);

			/* Select/Deselect All */
			multiSelect.on('click', '.btn-select-all, .btn-deselect-all', function() {
				element.multiSelect($(this).hasClass('btn-select-all') ? 'select_all' : 'deselect_all');
			});
		}
	});

	/**
	 * Init Settings
	 */
	fetch('/ajax/settings', {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		cache: 'no-cache',
		body: JSON.stringify({ dataType: 'json', async: false })
	}).then(response => response.json()).then(response => {
		/**
		 *
		 * @param {Object} response
		 * @param {Object} response.settings
		 * @param {Object} response.settings.maxFilesize
		 * @param {Number} response.settings.maxFilesize.B
		 * @param {Number} response.settings.maxFilesize.KB
		 * @param {Number} response.settings.maxFilesize.MB
		 * @param {Number} response.settings.maxFilesize.GB
		 *
		 */

		// Settings
		window.settings = response.settings;
	}).catch(error => console.error(error));

	/**
	 * Waypoint Script
	 *
	 * Add the .waypoint class to target elements, then assign css styles for an .active class.
	 * Add a data tag(example: data-thresh="50") to move the position of where the waypoint is triggered:
	 * The Data Thresh Value is what percentage of the object you would like to be visible before it is activated, keep in mind css transforms could skew the measurements.
	 * If you don't add a Data Tag it will use the default value of 80
	 */
	$(window).on('load scroll', function() {
		$('.waypoint').each(function() {
			var thresh     = typeof $(this).data('thresh') !== 'undefined' ? $(this).data('thresh') : 80,
				threshPerc = $(this).outerHeight() * (parseInt(thresh) / 100),
				winHeight  = $(window).height(),
				wayPoint   = $(this).offset().top - winHeight + threshPerc,
				scrollPos  = $(window).scrollTop();
			if(scrollPos > wayPoint) {
				$(this).addClass('active');
			} else if(scrollPos < wayPoint) {
				$(this).removeClass('active');
			}
		});
	});

	/**
	 * YouTube Modal Script
	 */
	$('[data-youtube-id]').on('click', function(event) {
		/* Prevent Default */
		event.preventDefault();

		/* Variable Defaults */
		var youtubeId    = $(this).data('youtube-id');
		var youtubeTitle = $(this).prop('title');

		/* Populate/Trigger Modal */
		$('#youtube-modal').find('.modal-body').html(
			$('<div class="ratio ratio-16x9">').append(
				$('<iframe/>', {
					'allowfullscreen': true,
					'title': youtubeTitle,
					'src': '//www.youtube.com/embed/' + youtubeId + '?autoplay=1&rel=0'
				}).one('load', function() {
					$(window).trigger('resize');
				})
			)
		).end().find('.modal-title').text(youtubeTitle).end();
	}).attr({
		'data-bs-toggle': 'modal',
		'data-target': '#youtube-modal'
	});

	/**
	 * Remove Modal Data on Close
	 */
	$('#youtube-modal').on('hide.bs.modal', function() {
		var that = $(this);
		setTimeout(function() {
			$(that).find('.modal-body, .modal-title').empty();
		}, 300);
	});

	/**
	 * Set default thumb if video thumb is missing
	 */
	$('.video-wrap[data-youtube-id]').each(function() {
		// Variable Defaults
		var parent     = $(this);
		var thumb      = parent.find('img');
		var youtube_id = parent.data('youtube-id');

		// Fetch List of Thumbnails
		$.get('https://www.googleapis.com/youtube/v3/videos', {
			id: youtube_id,
			key: 'AIzaSyCmOeKqKRNBhKHtXvFirWjXSyAE9G5aNcw',
			part: 'snippet',
			fields: 'items/snippet/thumbnails'
		}, function(response) {
			if(response.hasOwnProperty('items') && response.items.length) {
				thumb.attr('src', Object.keys(response.items[0].snippet.thumbnails).map(function(key) {
					return response.items[0].snippet.thumbnails[key];
				}).reduce(function(a, b) {
					return a.width > b.width && a.width <= 600 ? a.url : b.url;
				}));
			}
		});
	});

	/**
	 * Check for Render Error
	 */
	(function() {
		// Variable Defaults
		var error   = $('<div/>', { id: 'render-error' }).prependTo('body');
		var message = error.css('content');

		// Check Message
		message.replace('normal', '').length && console.error('You have a render error: ' + message);

		// Remove Error
		error.remove();
	})();

	/**
	 * Init Fancybox
	 */
	(function() {
		// Delegate Click Event to Parent
		$('.lightbox').on('click', 'a', function(event) {
			// Prevent Default
			event.preventDefault();

			// Variable Defaults
			var selected      = $(this);
			var selectedIndex = 0;

			// Show Fancybox
			Fancybox.show($(event.delegateTarget).find('a').map(function(index, element) {
				// Variable Defaults
				var $trigger = $(element);
				var $thumb   = $trigger.find('img');

				if($trigger.is(selected)) selectedIndex = index;

				// Return Object
				return {
					src: $trigger.attr('href'),
					thumb: $thumb.data('src') || $thumb.attr('src'),
					caption: $thumb.attr('alt'),
					type: 'image'
				};
			}).toArray(), {
				startIndex: selectedIndex
			});
		});
	})();

	/**
	 * Extend jQuery Deferred Hook
	 *
	 * @param error
	 * @param stack
	 */
	$.Deferred.exceptionHook = function(error, stack) {
		if(error && /^(Eval|Internal|Range|Reference|Syntax|Type|URI)Error$/.test(error.name)) {
			window.console.error(
				'jQuery.Deferred exception: ' + error.message,
				error.stack,
				stack
			);
		}
	};

	/**
	 * Bind Action to Open Modals
	 */
	$(this.body).on('click', '[data-modal]', function(event) {
		// Prevent Default
		event.preventDefault();

		// Variable Defaults
		var button   = $(this);
		var payload  = button.data();
		var endpoint = payload.modal;

		// Switch Endpoint
		switch(endpoint) {
			default:
				fetch('/modals/' + endpoint, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(payload)
				}).then(response => response.text()).then(html => {
					const elem  = document.createRange().createContextualFragment(html).children[0];
					const modal = new bootstrap.Modal(elem);
					modal._element.addEventListener('hidden.bs.modal', destroyModal);
					modal.show();
				}).catch(error => displayMessage(error, 'error'));
		}
	});

	/**
	 * Delegate Tooltips
	 */
	new bootstrap.Tooltip(this.body, {
		selector: '[rel^="tooltip"]',
		delay: { show: 500, hide: 0 },
		title: function() {
			// Variable Defaults
			var tooltip = $(this);
			var type    = tooltip.attr('rel').replace(/tooltip-/, '');
			var title   = '';

			// Handle Ajax Request
			$.ajax('/ajax/tooltips/' + type, {
				dataType: 'text',
				async: false,
				method: 'get',
				success: function(response) {
					title = response;
				}
			});

			// Fallback if Title is Empty
			if(!title.length) {
				tooltip.one('inserted.bs.tooltip', function() {
					$(this).tooltip('hide');
				});
			}

			// Dismiss on Click
			tooltip.one('click', function() {
				$(this).tooltip('hide');
			});

			return title;
		}
	});
});

/**
 * Window Load Section
 */
$(window).on('load', function() {
	// End Loading Animation
	hideLoader();

	// Animate Scroll to Anchor
	$('a[href*="#"]:not([href="#"])').on('click', function() {
		if(location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
			var target = $(this.hash);
			target     = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
			if(target.length) {
				$('html,body').animate({
					scrollTop: target.offset().top - 100
				}, 1000);
				return false;
			}
		}
	});
});

// Superscript Registered
(function() {
	var regex = /([\u00AE\u00A9])/g; // regular expression to match registered trademarks

	$('body :not(script,sup)').contents().filter(function() {
		return this.nodeType === 3 && regex.test(this.nodeValue);
	}).replaceWith(function() {
		return this.nodeValue.replace(regex, function(match) {
			return '<sup>&' + (match === '\u00AE' ? 'reg' : 'copy') + ';</sup>';
		});
	});
})();
