/**
 * Listen for the click and/or space bar/enter keypress event and return true or false
 *
 * @param event
 * @returns {boolean}
 */
function a11yClick(event) {
	if(event.type === 'click') {
		return true;
	} else if(event.type === 'keypress') {
		var code = event.charCode || event.keyCode;
		if((code === 32) || (code === 13)) {
			return true;
		}
	} else {
		return false;
	}
}

/**
 * add hidden or shown attributes to link(s)
 *
 * @param el
 * @param state
 */
export function links_attr(el, state) {
	var $aria_hidden = (state === 'show' ? 'false' : 'true');
	var $tabindex    = (state === 'show' ? '0' : '-1');
	el.attr({
		'aria-hidden': $aria_hidden,
		'tabindex': $tabindex
	});
}

/**
 * add hidden or shown attributes to link(s)
 *
 * @param el
 * @param state
 */
function container_attr(el, state) {
	var $aria_hidden = (state === 'show' ? 'false' : 'true');
	var $tabindex    = (state === 'show' ? '' : '-1');
	el.attr({
		'aria-hidden': $aria_hidden,
		'tabindex': $tabindex
	});
}

/**
 * add tab index to link(s)
 *
 * @param el
 * @param state
 */
function tab_index(el, state) {
	var $tabindex = (state === 'show' ? '0' : '-1');
	el.attr({
		'tabindex': $tabindex
	});
}

/**
 * Destroys Modal
 */
function destroyModal() {
	$(this).remove();
}

/*
 * Displays Message in Modal
 *
 * @param {string}	 text
 * @param {string}	 severity "alert" | "success"
 * @param {Function} [callbackFunction]
 */
function displayMessage(text, severity, callbackFunction) {
	// Check Open Modal
	if($('.modal.in, .modal.show').length) {
		alert(text);
	} else {
		// Reset Severity
		severity = (function() {
			switch(severity) {
				case 'alert':
				case 'error':
				case 'info':
				case 'success':
				case 'warning':
					return severity;
				default:
					return 'default';
			}
		})();

		// Handle Ajax
		fetch('/modals/notifications/' + severity, {
			method: 'POST',
			body: new URLSearchParams({ text: text })
		}).then(response => response.text()).then(html => {
			const elem  = document.createRange().createContextualFragment(html).children[0];
			const modal = new bootstrap.Modal(elem);
			modal._element.addEventListener('shown.bs.modal', () => {
				if(typeof callbackFunction === 'function') {
					callbackFunction(modal._element);
				}
			});
			modal._element.addEventListener('hidden.bs.modal', destroyModal);
			modal.show();
		}).catch(error => alert(error));
	}
}

/**
 * Focus Message Modal over Exiting Modal
 *
 * @returns {void}
 */
function focusDisplayMessage() {
	if($('.modal-backdrop').length >= 2) {
		$('.modal-backdrop:last').css('z-index', 9901);
		$('.modal:last').css('z-index', 9902);
	}
}

/**
 * Show Loading Animation
 *
 * @returns {void}
 */
function showLoader() {
	$('.loading-anim').removeClass('loaded');
}

/**
 * Hide Loading Animation
 *
 * @returns {void}
 */
function hideLoader() {
	$('.loading-anim').addClass('loaded');
}
