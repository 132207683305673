document.addEventListener('DOMContentLoaded', function() {
	// Delegate Click Functionality
	$(this.body).on('click', '.titlebar-trim-article [data-component-action]', function(event) {
		// Prevent Default
		event.preventDefault();

		// Variable Defaults
		var payload = $(this).data('component') || $(this).parents('[data-component]').data('component');
		var action  = $(this).data('component-action');

		// Switch Action
		switch(action) {
			case 'modal':
				// Render Modal
				$.ajax('/components/one-page-articles/titlebar-trim-article/modals/titlebar-trim-article.php', {
					data: payload,
					dataType: 'html',
					method: 'post',
					async: false,
					beforeSend: showLoader,
					complete: hideLoader,
					success: function(html) {
						// Init Modal
						const elem  = document.createRange().createContextualFragment(html).children[0];
						const modal = new bootstrap.Modal(elem);
						modal._element.addEventListener('hidden.bs.modal', destroyModal);
						modal.show();
					}
				});
				break;
			default:
				console.error('Unknown Component Action', action);
		}
	});
});