document.addEventListener('DOMContentLoaded', function() {
	Object.assign(Fancybox.defaults, {
		Thumbs: { type: 'classic' },
		Toolbar: {
			display: {
				left: ['infobar'],
				middle: [
					'zoomIn',
					'zoomOut',
					'toggle1to1',
					'rotateCCW',
					'rotateCW',
					'flipX',
					'flipY'
				],
				right: ['download', 'slideshow', 'thumbs', 'close']
			}
		}
	});
});